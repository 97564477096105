import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "mobilnycrm4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Aplikacjacrm = ({ data }) => {
  return (
    <Artykul
      title="Aplikacja CRM: ułatwiona praca w terenie"
      keywords={["aplikacja crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Aplikacja CRM: ułatwiona praca w terenie"
      metaTitle="Aplikacja CRM: ułatwiona praca w terenie"
      metaDescription="Aplikacja CRM i responsywna wersja webowa to dwa wygodne sposoby na pracę zdalną z systemem. ✅ Sprawdź swoje możliwości
na pracę w terenie z systemem CRM • Video 2020"
    >
      <br />
      <p>
        W kolejnej odsłonie naszego vloga o zarządzaniu za pomocą systemu CRM
        pochylimy się nad zasugerowanym przez Was tematem zdalnej pracy na tym
        rozwiązaniu. W jaki sposób możesz pracować na naszym systemie, jeżeli
        jesteś na wyjeździe poza firmą? Drogi są dwie - albo aplikacja CRM, albo
        wykorzystanie jego odpowiednio skonfigurowanej wersji webowej. W obu
        przypadkach wykorzystać można tablety i smartfony.
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/7eiEBXoOiNA"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <p>
        Tak więc, przebywając poza biurem i korzystając ze wspomnianych
        urządzeń, mamy dwie opcje - możemy pracować przy użyciu normalnej,
        przeglądarkowej wersji naszego systemu (która automatycznie dostosowuje
        się do rozmiaru ekranu) lub specjalnej aplikacji mobilnej łączącej się z
        CRM. Oba te rozwiązania są nieco inne, co pokrótce wyjaśnimy poniżej.
      </p>
      <br />
      <h2>CRM w przeglądarce</h2>
      <p>
        Najpierw responsywna wersja webowa systemu. Umożliwia ona korzystanie z
        dokładnie tych samych funkcji i możliwości, jak ma to miejsce w
        przypadku pracy na komputerze. Najpoważniejsza różnica sprowadza się
        właściwie głównie do rozmiaru wyświetlanych elementów systemu, które
        automatycznie dostosowują się do wielkości używanego ekranu.
      </p>

      <p>
        Dobrym sposobem na ułatwienie sobie pracy z tą wersją systemu, warto
        umieścić na swoim pulpicie skrót do internetowego adresu CRM. W dłuższej
        perspektywie wpłynie to na znaczną oszczędność czasu, bowiem nie będzie
        trzeba za każdym razem wpisywać wspomniany adres ręcznie. Wystarczy
        jedno kliknięcie na pulpicie, aby znaleźć się w systemie. Zależnie od
        tego, czy nasze urządzenie działa w środowisku Android czy iOS, taki
        skrót konfiguruje się nieco inaczej. Wszelkie niezbędne informacje
        znajdziecie pod poniższymi linkami:
      </p>

      <a href="https://support.google.com/chromebook/answer/3113576?hl=pl">
        <strong>Konfiguracja w Androidzie</strong>
      </a>
      <br />
      <a href="https://support.apple.com/pl-pl/guide/iphone/iph42ab2f3a7/ios">
        <strong>Konfiguracja w iOS</strong>
      </a>

      <p>
        W wersji responsywnej system CRM nie został w żaden sposób ograniczony i
        umożliwia korzystanie z pełni swoich funkcji. Wszystkie zdefiniowane
        wcześniej raporty i zestawienia są dostępne również i tutaj, tak samo
        jak historia współpracy z klientem i pozostałe, stosowane w codziennej
        pracy funkcje. W celu ułatwienia sobie pracy na pomniejszonym pulpicie,
        warto go wcześniej odpowiednio przygotować - dbając o to, by znajdowały
        się na nim tylko najpotrzebniejsze dane. Dzięki temu będzie bardziej
        przejrzysty na mniejszym ekranie urządzenia przenośnego.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz korzystać z aplikacji mobilnej CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM wraz aplikacją

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować aplikację CRM
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Aplikacja CRM</h2>
      <p>
        Mobilna aplikacja CRM to drugi ze sposobów na dostęp do zebranych w
        systemie zasobów. Instalacja tego oprogramowania jest bardzo prosta -
        wystarczy je pobrać ze sklepów Google Play lub App Store. Logowanie się
        do nich również nie jest skomplikowane - wystarczy podać internetowy
        adres systemu oraz odpowiednie hasło i login. Ukazujący się tuż po
        zalogowaniu pulpit oferuje przeniesienie do wersji webowej, dodanie
        nowej osoby do kontaktu, zlecenie nowego zadania lub skorzystanie z
        jednej z sekcji.
      </p>

      <p>
        W sekcji kontaktów wyszukuje się konkretnych klientów oraz ich osoby
        kontaktowe. Można tutaj również szybko wybrać numer telefonu do
        konkretnego pracownika kontrahenta.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 300,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Sekcja z zadaniami w aplikacji CRM."]}
      />
      <br />
      <p>
        Sekcja zadań umożliwia zaś szybki podgląd zadań zaplanowanych na bieżący
        dzień lub tych zaległych, których jeszcze nie zdążyliśmy wykonać. W tym
        miejscu można również odznaczyć wykonanie jakiegoś zadania - aplikacja
        CRM wyśle tę informację do głównego systemu, gdzie będzie ona widoczna
        niemalże natychmiast.
      </p>

      <p>
        Za pomocą powiadomień PUSH aplikacja CRM da również użytkownikom znać o
        nowej wiadomości w systemie. Może ponadto przekazywać do systemu
        informację o lokalizacji pracownika - o ile zezwolisz jej na to w
        ustawieniach.
      </p>
      <br />
      <h2>Synchronizacja CRM z kalendarzem</h2>
      <p>
        Jeśli jesteś przyzwyczajony do pracy z wbudowaną w telefon aplikacją
        kalendarza, to mamy dobrą wiadomość - ją także możesz zsynchronizować z
        systemem CRM. Aby to zrobić, po prostu skonfiguruj połączenie między
        aplikacjami za pomocą protokołu CalDAV - nasz dział techniczny z
        przyjemnością podpowie Ci, jak to zrobić. Wystarczy się z nami
        skontaktować, a prześlemy Ci odpowiednie instrukcje.
      </p>
      <br />
      <h2>Na zakończenie</h2>
      <p>
        Nasza aplikacja CRM jest praktyczną pomocą dla każdego, kto podróżuje
        służbowo i często pracuje poza siedzibą firmy. Głównymi jej zaletami są
        przejrzystość, szybkość i łatwość w obsłudze. Jeśli masz nieco więcej
        czasu potrzebujesz bogatszego zestawu opcji - nie ma problemu, webowa
        wersja systemu CRM dostosuje się do Twojego urządzenia przenośnego.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz korzystać z aplikacji mobilnej CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM wraz aplikacją

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować aplikację CRM
            </Link>
          </div>
          </div>
    </section>
      <p>
        <ul>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM">
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm">
              Zalety elastycznego systemu CRM
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Aplikacjacrm;
